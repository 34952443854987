import type { VmImage, VmImageVersion } from "@warrenio/api-spec/spec.oats.gen";
import type { ReactNode } from "react";
import { useState } from "react";
import { WButton } from "../../components/button/WButton.tsx";
import { FormField } from "../../components/forms/FormField.tsx";
import { IconRadio } from "../../components/forms/IconRadio.tsx";
import { WCheckbox } from "../../components/forms/WCheckbox.tsx";
import { WFileUploadField } from "../../components/forms/WFileUploadField.tsx";
import { WRadioGroup } from "../../components/forms/WRadioGroup.tsx";
import { WTextField } from "../../components/forms/WTextField.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { WTable, WTableBody } from "../../components/table/WTable.tsx";
import { InfoTooltip, WTooltip } from "../../components/WTooltip.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import type { ItemType } from "../../modules/compute/vmCreateViewModel.tsx";
import { AInput } from "../form/Fields.tsx";
import { vmImageCreateMutation, type VmImageType, vmImageUpdateMutation } from "./vmImagesQuery.ts";

const types = [
    {
        id: "virtual_machine",
        title: "Virtual Machine",
        icon: "jp-virtual-machine-icon",
        description: "",
    },
    {
        id: "app_catalog",
        title: "App Catalog",
        icon: "jp-icon-appcatalog",
        description: "",
    },
] as const satisfies ItemType[];

export function AddEditVmImageButton({ button }: { button: ReactNode }) {
    return (
        <WModal button={button}>
            <AddEditVmImageModalContent />
        </WModal>
    );
}

export function EditHostPoolButton({ item, button }: { item: VmImage; button: ReactNode }) {
    return (
        <WModal button={button}>
            <AddEditVmImageModalContent item={item} />
        </WModal>
    );
}

export function AddEditVmImageModalContent({ item }: { item?: VmImage }) {
    const defaultVersions: VmImageVersion[] = [
        {
            os_version: "",
            display_name: "",
            published: false,
        },
    ];

    const initialType = item?.is_app_catalog ? "app_catalog" : "virtual_machine";

    const createMutation = useStandardMutation(vmImageCreateMutation);
    const updateMutation = useStandardMutation(vmImageUpdateMutation);
    const [type, setType] = useState<string>(initialType);
    const [os_name, setOsName] = useState<string>(item?.os_name ?? "");
    const [display_name, setDisplayName] = useState<string>(item?.display_name ?? "");
    const [file, setFile] = useState<string | undefined>(item?.icon);
    const [isDefault, setIsDefault] = useState<boolean>(item?.is_default ?? false);
    const [versions, setVersions] = useState<VmImageVersion[]>(item?.versions ?? defaultVersions);
    const image_type: VmImageType = type === "app_catalog" ? "app_catalog" : "plain_os";

    const isEditMode = !!item;
    const availableTypes = types;
    const invalidVersions = versions.some((item) => !item.os_version || !item.display_name);

    async function onSubmit() {
        const body: VmImage = {
            os_name: os_name,
            display_name: display_name,
            ui_position: item?.ui_position ?? 0,
            is_default: isDefault,
            is_app_catalog: item?.is_app_catalog ?? false,
            icon: file ?? "",
            versions: versions,
        };

        if (isEditMode) {
            await updateMutation.mutateAsync({
                body,
                os_name: item.os_name,
                image_type,
            });
        } else {
            await createMutation.mutateAsync({
                body,
                image_type,
            });
        }
    }

    function toggleDefault(value: boolean) {
        setIsDefault(value);
    }

    function setVersionValue(index: number, key: "os_version" | "display_name" | "published", value: string | boolean) {
        const items = versions.map((item, i) => (i === index ? { ...item, [key]: value } : item));
        setVersions(items);
    }

    function addVersion() {
        const items = [...versions, ...defaultVersions];
        setVersions(items);
    }

    function deleteVersion(index: number) {
        setVersions((prevVersions) => {
            const updatedVersions = [...prevVersions];
            updatedVersions.splice(index, 1);
            return updatedVersions;
        });
    }

    return (
        <WModalContent
            title={isEditMode ? `Update ${item.display_name}` : "Create New VM image"}
            label={isEditMode ? "Edit" : "Create"}
            isActionDisabled={!os_name || !display_name || invalidVersions}
            modalAction={async () => await onSubmit()}
        >
            <WRadioGroup label="Type" isReadOnly={isEditMode} isRequired value={type} onChange={setType}>
                {availableTypes.map((item) => (
                    <IconRadio key={item.id} {...item} />
                ))}
            </WRadioGroup>

            <WTextField
                isReadOnly={isEditMode}
                isRequired
                label="Name"
                onChange={setOsName}
                description="Full image name will be <name>_<version>, this is the <name> prefix. Must be unique, cannot be changed."
            >
                <AInput value={os_name} />
            </WTextField>

            <WTextField
                isRequired
                label="Display Name"
                onChange={setDisplayName}
                description="User friendly name shown in UI."
            >
                <AInput value={display_name} />
            </WTextField>

            <WFileUploadField
                onChange={(v) => setFile(v)}
                value={file ?? ""}
                label="Icon"
                description="Icon shown in UI. Accepted format is SVG and file size under 10kb."
            />

            <FormField label="Set as Default" wide>
                <WCheckbox isSelected={isDefault} onChange={toggleDefault}>
                    When checked current image will be preselected in UI.
                </WCheckbox>
            </FormField>

            <FormField label="Versions" wide isRequired>
                <WTable>
                    <thead>
                        <tr>
                            <th>
                                Version
                                <InfoTooltip
                                    className="ml-1"
                                    text="Full image name will be &lt;name&gt;_&lt;version&gt;, this is the &lt;version&gt; suffix."
                                />
                            </th>
                            <th>
                                Display Name
                                <InfoTooltip className="ml-1" text="User friendly version shown in UI." />
                            </th>
                            <th>
                                Published
                                <InfoTooltip
                                    className="ml-1"
                                    text="Unpublished version will be visible only for admins."
                                />
                            </th>
                            <th />
                        </tr>
                    </thead>
                    <WTableBody>
                        {versions.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <WTextField
                                        headless
                                        isRequired
                                        aria-label="Version"
                                        onChange={(e) => setVersionValue(index, "os_version", e)}
                                    >
                                        <AInput value={item.os_version} />
                                    </WTextField>
                                </td>
                                <td>
                                    <WTextField
                                        headless
                                        isRequired
                                        aria-label="Display Name"
                                        onChange={(e) => setVersionValue(index, "display_name", e)}
                                    >
                                        <AInput value={item.display_name} />
                                    </WTextField>
                                </td>
                                <td>
                                    <WCheckbox
                                        isSelected={item.published}
                                        onChange={(e) => setVersionValue(index, "published", e)}
                                    >
                                        Published
                                    </WCheckbox>
                                </td>
                                <td>
                                    <div className="flex gap-0.5em justify-end">
                                        <WTooltip text="Delete">
                                            <WButton
                                                action={() => deleteVersion(index)}
                                                size="xs"
                                                color="primary"
                                                variant="ghost"
                                                ariaLabel="Delete"
                                                icon="jp-trash-icon"
                                            />
                                        </WTooltip>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </WTableBody>
                </WTable>

                <div className="pt-2">
                    <WButton
                        className="w-full"
                        label="Add New Version"
                        color="primary"
                        size="md"
                        variant="dashed"
                        icon="jp-icon-add"
                        action={() => addVersion()}
                    />
                </div>
            </FormField>
        </WModalContent>
    );
}
